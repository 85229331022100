<template>
  <span class="status-badge" :class="statusClass">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'MemlistBadge',
  props: {
    status: {
      type: String,
      default: 'unpublished',
      validator: v => ['published', 'unpublished'].includes(v)
    }
  },
  computed: {
    statusClass() {
      return this.status;
    }
  }
}
</script>

<style scoped>
.status-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  font-size: 0.85em;
  padding: 4px 8px;
  border-radius: 1pc;
  border: 1px solid;
  text-align: center;
  font-weight: bold;
  overflow: hidden;
}

.status-badge.published {
  background-color: white;
  color: #4caf50;
  border-color: #4caf50;
}

.status-badge.unpublished {
  background-color: white;
  color: #ff6a6a;
  border-color: #ff6a6a;
}
</style>
