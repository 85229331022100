<template>
  <div class="memlist-slider-input-container">
    <div v-if="layout === 'horizontal'">
      <div v-if="title" class="horizontal1">
        <label style="font-weight: bold;">{{ title }}</label>
      </div>
      <div class="horizontal2">
        <input
          type="range"
          :min="min"
          :max="max"
          :step="step"
          v-model.number="local_value"
          class="slider"
        />
        <span class="slider-value">{{ local_value.toFixed(2) }}</span>

        <div class="error-text" v-if="required && invalid_text && invalid_text.length > 0 && local_state === false">
          {{ invalid_text }}
        </div>

        <div class="info-text">{{ info_text }}</div>
      </div>
    </div>

    <div v-if="layout === 'vertical'">
      <div v-if="title" class="vertical1">
        <label style="font-weight: bold;">{{ title }}</label>
      </div>
      <div class="vertical2">
        <input
          type="range"
          :min="min"
          :max="max"
          :step="step"
          v-model.number="local_value"
          class="slider"
        />
        <span class="slider-value">{{ local_value.toFixed(2) }}</span>

        <div class="error-text" v-if="required && invalid_text && invalid_text.length > 0 && local_state === false">
          {{ invalid_text }}
        </div>

        <div class="info-text">{{ info_text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MemlistSliderInput',

  props: {
    layout: {
      type: String,
      default: 'horizontal',
    },
    title: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 2,
    },
    step: {
      type: Number,
      default: 0.05,
    },
    validate: {
      type: Number,
      default: 0,
    },
    invalid_text: {
      type: String,
      default: '',
    },
    info_text: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: 1, // Default scale to 1
    }
  },

  emits: ['input', 'validated'],

  data() {
    return {
      local_state: null,
      local_value: this.value,
    };
  },

  watch: {
    local_value(newValue) {
      this.$emit('input', newValue);
    },
    value(newValue) {
      this.local_value = newValue;
    }
  }
};
</script>

<style scoped>
/* Slider Input Styles */
.memlist-slider-input-container {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.slider {
  width: 100%;
  -webkit-appearance: none;
  height: 6px;
  background: #ddd;
  border-radius: 5px;
  outline: none;
  transition: 0.3s;
}

.slider:hover {
  background: #bbb;
}

.slider-value {
  font-size: 14px;
  font-weight: bold;
  margin-left: 8px;
}

.error-text {
  color: red;
  font-size: 12px;
}

.info-text {
  font-size: 12px;
  color: #666;
}
</style>
