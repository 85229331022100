<template>
  <div class="m-overlay ml-modal" v-if="visible || show_override" @mousedown="on_overlay_mousedown">
    <div class="m-container" :style="calculated_style" @click.stop>
      <button class="m-close" @click="close_clicked">
        <i class="fa fa-times"></i>
      </button>
      <div class="m-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MemlistModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'light',
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  data() {
    return {
      show_override: false
    }
  },
  methods: {
    show() {
      this.show_override = true;
    },
    hide() {
      this.show_override = false;
    },
    
    on_overlay_mousedown(e) {
      if (e.target === this.$el) {
        this.overlay_mouse_down = true;
        document.addEventListener('mouseup', this.on_document_mouseup);
      }
    },
    on_document_mouseup(e) {
      if (this.overlay_mouse_down && e.target === this.$el) {
        this.close_clicked();
      }
      this.overlay_mouse_down = false;
      document.removeEventListener('mouseup', this.on_document_mouseup);
    },
    
    close_clicked() {
      this.show_override = false;
      this.$emit('close');
    }
  },
  computed: {
    calculated_style() {

      const style = {
        'background-color': 'white',
        'width': '320px',
      }

      if (this.theme === 'dark') {
        style['background-color'] = 'rgb(33, 34, 45)';
      }

      switch (this.size) {
        case 'sm':
          style['width'] = '320px';
          break;
        case 'lg':
          style['width'] = '640px';
          break;
        case 'xl':
          style['width'] = '1024px';
          break;
        case 'xxl':
          style['width'] = '1360px';
          break;
        case 'full':
          style['width'] = '100%';
          break;
      }

      return style;
    }
  }
}
</script>

<style scoped>
.m-overlay {
  position: fixed;
  inset: 0; /* replaces top, left, right, bottom */
  background: rgba(0,0,0,0.5);
  display: flex;
  align-items: flex-start; 
  justify-content: center;
  z-index: 999;
  overflow-y: auto;
}

.m-container {
  margin-top: 32px;
  position: relative;
  background: #fff;
  border-radius: 8px;
  padding: 1em;
  padding-top: 2.8em;
  box-shadow: 0 4px 20px rgba(0,0,0,0.2);
}

.m-close {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  line-height: 1;
}

.m-content {
  margin-top: 2em;
}

.m-close {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 50%;
  background-color: rgba(128, 128, 128, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.1s;
}

.m-close i {
  font-size: 16px;
  color: #333;
}

.m-close:hover {
  background-color: rgba(128, 128, 128, 0.4);
}

.m-close:active {
  transform: scale(0.95);
}


</style>
