<template>
  <div class="ml-card">
    <div v-if="title" class="ml-card-header">
      {{ title }}
    </div>
    <div class="ml-card-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MemlistCard',
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>
.ml-card {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  font-family: 'Poppins';
  font-size: 16px;
}

.ml-card-header {
  padding: 16px;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 1px solid #dee2e6;
  background-color: #f8f9fa;
}

.ml-card-body {
  padding: 16px;
  font-size: 14px;
  color: #333;
}
</style>
