<template>
  <div class="m-row">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'MRow'
}
</script>

<style scoped>
.m-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
}
</style>
