import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";

// Localisation language list
import { locale as en } from "@/core/config/i18n/en";
import { locale as sv } from "@/core/config/i18n/sv";
import { locale as ru } from "@/core/config/i18n/ru";

Vue.use(VueI18n);

let messages = { en, sv, ru };

// Get current selected language
const lang = localStorage.getItem("language") || "sv";

// Create VueI18n instance
const i18n = new VueI18n({
  locale: lang,
  messages
});

// Function to fetch and apply language overrides
export async function loadCustomOverrides() {
  try {
    console.log('loading overrides');
    const response = await axios.get("/system/lang");
    if (response.data.has_custom && response.data.data) {
      console.log('has custom', response.data.data, Object.keys(messages));
      Object.keys(response.data.data).forEach((locale) => {

        if (messages[locale]) {
          deepMerge(messages[locale], response.data.data[locale]);
        }
      });
    }
  } catch (error) {
    console.error("Error loading custom language overrides:", error);
  }
}

// Deep merge function to apply overrides
function deepMerge(target, source) {
  for (const key of Object.keys(source)) {
    console.log('apply merge key', key);
    if (source[key] instanceof Object && target[key] instanceof Object) {
      deepMerge(target[key], source[key]);
    } else {
      target[key] = source[key];
      console.log('setting', source[key])
    }
  }
}

export default i18n;
