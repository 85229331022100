<template>
  <button
    class="ml-icon-button"
    :class="`ml-icon-button-${variant}`"
    @click.prevent="$emit('click')"
  >
    <i :class="icon" class="mr-2 icon-inherit"></i>
    <span>{{ text }}</span>
  </button>
</template>

<script>
export default {
  name: 'MemlistIconButton',
  props: {
    icon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    variant: {
      type: String,
      default: 'primary',
      validator: v =>
        ['primary', 'primary-outline', 'success', 'success-outline', 'danger', 'danger-outline'].includes(v)
    }
  }
}
</script>

<style scoped>
.ml-icon-button {
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid transparent;
  transition: background-color 0.2s ease, border-color 0.2s ease;
}

.ml-icon-button-primary {
  background-color: #3699FF;
  color: #fff;
}
.ml-icon-button-primary:hover {
  background-color: #277dd3;
}

.ml-icon-button-primary-outline {
  background-color: transparent;
  color: #3699FF;
  border-color: #3699FF;
}
.ml-icon-button-primary-outline:hover {
  background-color: #e6f0ff;
}

.ml-icon-button-success {
  background-color: #28a745;
  color: #fff;
}
.ml-icon-button-success:hover {
  background-color: #218838;
}

.ml-icon-button-success-outline {
  background-color: transparent;
  color: #28a745;
  border-color: #28a745;
}
.ml-icon-button-success-outline:hover {
  background-color: #e6f9ed;
}

.ml-icon-button-danger {
  background-color: #dc3545;
  color: #fff;
}
.ml-icon-button-danger:hover {
  background-color: #c82333;
}

.ml-icon-button-danger-outline {
  background-color: transparent;
  color: #dc3545;
  border-color: #dc3545;
}
.ml-icon-button-danger-outline:hover {
  background-color: #fbeaec;
}
.icon-inherit {
  font-size: 1em;
  color: inherit;
}

</style>
