<template>
  <div class="ml-spinner" role="status" aria-label="Loading...">
    <span :class="['ml-spinner-icon', sizeClass]"></span>
    <span class="ml-spinner-text" v-if="label">{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: 'MemlistSpinner',
  props: {
    label: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'md',
      validator: val => ['sm', 'md', 'lg'].includes(val)
    }
  },
  computed: {
    sizeClass() {
      return {
        sm: 'ml-spinner-sm',
        md: 'ml-spinner-md',
        lg: 'ml-spinner-lg'
      }[this.size];
    }
  }
}
</script>

<style scoped>
.ml-spinner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ml-spinner-icon {
  border: 0.3rem solid #dee2e6;
  border-top-color: #007bff;
  border-radius: 50%;
  animation: spin 0.75s linear infinite;
}

.ml-spinner-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2rem;
}

.ml-spinner-md {
  width: 2rem;
  height: 2rem;
}

.ml-spinner-lg {
  width: 3rem;
  height: 3rem;
  border-width: 0.4rem;
}

.ml-spinner-text {
  margin-left: 12px;
  font-size: 16px;
  color: #333;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
