<template>
  <div :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'MCol',
  props: {
    cols: [String, Number],
    sm: [String, Number],
    md: [String, Number],
    lg: [String, Number],
    xl: [String, Number]
  },
  computed: {
    classes() {
      const base = [];

      if (this.cols) base.push(`m-col-${this.cols}`);
      if (this.sm) base.push(`m-col-sm-${this.sm}`);
      if (this.md) base.push(`m-col-md-${this.md}`);
      if (this.lg) base.push(`m-col-lg-${this.lg}`);
      if (this.xl) base.push(`m-col-xl-${this.xl}`);

      return base;
    }
  }
}
</script>

<style scoped>
[class*="m-col"] {
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
  flex: 1 1 0%; /* ⬅️ ensures it stretches full row width unless width is set */
}


/* default full width */
.m-col-12 {
  width: 100%;
}

/* generate grid for 1–12 */
.m-col-1  { width: 8.3333%; }
.m-col-2  { width: 16.6667%; }
.m-col-3  { width: 25%; }
.m-col-4  { width: 33.3333%; }
.m-col-5  { width: 41.6667%; }
.m-col-6  { width: 50%; }
.m-col-7  { width: 58.3333%; }
.m-col-8  { width: 66.6667%; }
.m-col-9  { width: 75%; }
.m-col-10 { width: 83.3333%; }
.m-col-11 { width: 91.6667%; }
.m-col-12 { width: 100%; }

/* responsive columns */
@media (max-width: 576px) {
  .m-col-sm-1  { width: 8.3333%; }
  .m-col-sm-2  { width: 16.6667%; }
  .m-col-sm-3  { width: 25%; }
  .m-col-sm-4  { width: 33.3333%; }
  .m-col-sm-5  { width: 41.6667%; }
  .m-col-sm-6  { width: 50%; }
  .m-col-sm-7  { width: 58.3333%; }
  .m-col-sm-8  { width: 66.6667%; }
  .m-col-sm-9  { width: 75%; }
  .m-col-sm-10 { width: 83.3333%; }
  .m-col-sm-11 { width: 91.6667%; }
  .m-col-sm-12 { width: 100%; }
}

@media (min-width: 768px) {
  .m-col-md-1  { width: 8.3333%; }
  .m-col-md-2  { width: 16.6667%; }
  .m-col-md-3  { width: 25%; }
  .m-col-md-4  { width: 33.3333%; }
  .m-col-md-5  { width: 41.6667%; }
  .m-col-md-6  { width: 50%; }
  .m-col-md-7  { width: 58.3333%; }
  .m-col-md-8  { width: 66.6667%; }
  .m-col-md-9  { width: 75%; }
  .m-col-md-10 { width: 83.3333%; }
  .m-col-md-11 { width: 91.6667%; }
  .m-col-md-12 { width: 100%; }
}

@media (min-width: 992px) {
  .m-col-lg-1  { width: 8.3333%; }
  .m-col-lg-2  { width: 16.6667%; }
  .m-col-lg-3  { width: 25%; }
  .m-col-lg-4  { width: 33.3333%; }
  .m-col-lg-5  { width: 41.6667%; }
  .m-col-lg-6  { width: 50%; }
  .m-col-lg-7  { width: 58.3333%; }
  .m-col-lg-8  { width: 66.6667%; }
  .m-col-lg-9  { width: 75%; }
  .m-col-lg-10 { width: 83.3333%; }
  .m-col-lg-11 { width: 91.6667%; }
  .m-col-lg-12 { width: 100%; }
}

@media (min-width: 1200px) {
  .m-col-xl-1  { width: 8.3333%; }
  .m-col-xl-2  { width: 16.6667%; }
  .m-col-xl-3  { width: 25%; }
  .m-col-xl-4  { width: 33.3333%; }
  .m-col-xl-5  { width: 41.6667%; }
  .m-col-xl-6  { width: 50%; }
  .m-col-xl-7  { width: 58.3333%; }
  .m-col-xl-8  { width: 66.6667%; }
  .m-col-xl-9  { width: 75%; }
  .m-col-xl-10 { width: 83.3333%; }
  .m-col-xl-11 { width: 91.6667%; }
  .m-col-xl-12 { width: 100%; }
}
</style>
