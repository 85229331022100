<template>
  <div class="ml-alert" :class="`ml-alert-${variant}`" v-if="show">
    <div class="ml-alert-content">
      <div>
        <span class="ml-alert-icon">
          <i class="fa fa-question-circle color-primary"></i>
        </span>
      </div>
      <div class="ml-alert-text">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MemlistInlineAlert',
  props: {
    variant: {
      type: String,
      default: 'danger'
    },
    show: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>
.ml-alert {
  border-radius: 6px;
  margin: 20px 0;
  padding: 0;
  font-size: 16px;
  transition: opacity 0.3s ease;
}

.ml-alert-danger {
  background-color: #f8d7da;
  color: #721c24;
}

.ml-alert-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
}

.ml-alert-icon {
  font-size: 24px;
}

.ml-alert-text {
  margin-left: 16px;
}
</style>
