<template>
  <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100">
    <memlist-button class="mr-2" variant="secondary" @click.prevent="$emit('close')">{{ cancel_text ? cancel_text : $t('COMMON.CLOSE') }}</memlist-button>


    <div v-if="!(hide_primary === true)">
      <memlist-button v-if="spin" variant="primary" :disabled="disabled || spinning" @click.prevent="on_save_clicked">
        <memlist-spinner size="sm" v-if="spinning" class="mr-2" />
        {{ text }}
      </memlist-button>

      <memlist-button v-else variant="primary" :disabled="disabled" @click.prevent="on_save_clicked">
        {{ text }}
      </memlist-button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';

import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'RightModalSaveAndCloseButtons',
  components: {

  },
  mixins: [ toasts ],
  props: ['text','disabled','spin','hide_primary','cancel_text'],
  emits: ['clicked', 'close'],
  computed: {
    ...mapGetters([])
  },
  data() {
    return {
      spinning: false
    };
  },
  watch: {

  },
  mounted() {

  },
  methods: {
    stop() {
      const that = this;

      setTimeout(()=>{
        that.spinning = false;
      }, 500);
    },

    on_save_clicked() {
      this.spinning = true;

      this.$emit('clicked');
    }
  }
};
</script>

<style scoped>

</style>
