<template>
  <button
    class="ml-button"
    :type="type"
    :class="[
      `ml-button-${variant}`,
      { 'ml-button-disabled': disabled }
    ]"
    :disabled="disabled"
    @click="onClick"
  >
    <slot></slot>
  </button>
</template>


<script>
export default {
  name: 'MemlistButton',
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator: v => ['primary', 'secondary', 'danger', 'success'].includes(v)
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "button"
    }
  },
  methods: {
    onClick(event) {
      if (!this.disabled) {
        this.$emit('click', event)
      }
    }
  }
}

</script>

<style scoped>

.ml-button {
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.1s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ml-button:active {
  transform: scale(0.97);
}

/* Primary */
.ml-button-primary {
  background-color: #3699FF;
  color: #fff;
}
.ml-button-primary:hover:not(:disabled) {
  background-color: #1c78d4;
}

/* success */
.ml-button-success {
  background-color: #1bc5bd;
  color: #fff;
}
.ml-button-success:hover:not(:disabled) {
  background-color: #1fdbd2;
}

/* Secondary */
.ml-button-secondary {
  background-color: #6c757d;
  color: #fff;
}
.ml-button-secondary:hover:not(:disabled) {
  background-color: #5a6268;
}

/* Danger */
.ml-button-danger {
  background-color: #dc3545;
  color: #fff;
}
.ml-button-danger:hover:not(:disabled) {
  background-color: #c82333;
}

/* Disabled */
.ml-button:disabled,
.ml-button.ml-button-disabled {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

</style>
