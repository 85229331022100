require('dotenv').config({ path: __dirname + '/.env' });

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/core/services/store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueGoogleMap from 'vuejs-google-maps';
import VueTables2 from 'vue-tables-2';

Vue.use(VueAxios, axios);

if (process.env.NODE_ENV === 'production') {
  Vue.axios.defaults.baseURL = '/api/v3';
} else if (process.env.NODE_ENV === 'development') {
  Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL || 'http://localhost/api/v3';
  window.domain = process.env.VUE_APP_API_DOMAIN;
}

// Ensure headers are set before axios requests
Vue.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

import '@/core/services/axios.service';

Vue.config.productionTip = false;

// Global plugins
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;

// Import i18n setup
import vuetify from '@/core/plugins/vuetify';
import i18n, { loadCustomOverrides } from '@/core/plugins/vue-i18n';

// Import other plugins
import '@/core/plugins/portal-vue';
import '@/core/plugins/bootstrap-vue';
import '@/core/plugins/perfect-scrollbar';
import '@/core/plugins/vuelidate';
import '@/core/plugins/inline-svg';
import '@/core/plugins/metronic';
import '@mdi/font/css/materialdesignicons.css';
import '@/core/plugins/memlist.css';
import '@/assets/sass/components/forms/_memlist_checkbox.scss';
import '@/assets/sass/components/forms/_memlist_table.scss';
import '@/assets/sass/components/forms/_memlist_base.scss';

// Vue plugins
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);

import fullscreen from 'vue-fullscreen';
Vue.use(fullscreen);

import { func } from '@/core/services/func.service.js';
Vue.prototype.$func = func;

Vue.use(VueTables2.ClientTable);

Vue.use(VueGoogleMap, {
  load: {
    apiKey: 'AIzaSyA6TAPuhK96gq9vMpeF27m6icCgqkqn1rM',
    libraries: []
  }
});


import MemlistSelectInput from '@/view/components/form/MemlistSelectInput.vue';
import MemlistSelectInput2 from '@/view/components/form/MemlistSelectInput2.vue';
import MemlistTextInput from '@/view/components/form/MemlistTextInput.vue';
import MemlistDatePicker from '@/view/components/form/MemlistDatePicker.vue';
import MemlistTimePicker from '@/view/components/form/MemlistTimePicker.vue';
import MemlistDateTimePicker from '@/view/components/form/MemlistDateTimePicker.vue';
import MemlistDropdown from '@/view/components/form/MemlistDropdown.vue';
import MemlistCheckbox from '@/view/components/form/MemlistCheckbox.vue';
import MemlistContrastButton from '@/view/components/form/MemlistContrastButton.vue';
import MemlistCircularButton from '@/view/components/form/MemlistCircularButton.vue';
import MemlistCircularSelectButton from '@/view/components/form/MemlistCircularSelectButton.vue';
import MemlistCircularInputSelectButton from '@/view/components/form/MemlistCircularInputSelectButton.vue';
import MemlistSegmentedSelectButton from '@/view/components/form/MemlistSegmentedSelectButton.vue';
import MemlistModal from '@/view/components/form/MemlistModal.vue';
import MemlistCodeBox from '@/view/components/form/MemlistCodeBox.vue';
import MemlistTextarea from '@/view/components/form/MemlistTextarea.vue';
import MemlistSliderInput from '@/view/components/form/MemlistSliderInput.vue';
import MemlistFileUpload from '@/view/components/form/MemlistFileUpload.vue';
import MemlistInlineAlert from '@/view/components/form/MemlistInlineAlert.vue';
import MemlistSpinner from '@/view/components/form/MemlistSpinner.vue';
import MemlistCard from '@/view/components/form/MemlistCard.vue';
import MemlistButton from '@/view/components/form/MemlistButton.vue';
import MCol from '@/view/components/form/MCol.vue';
import MRow from '@/view/components/form/MRow.vue';
import MemlistBadge from '@/view/components/form/MemlistBadge.vue';
import MemlistIconButton from '@/view/components/form/MemlistIconButton.vue';

import MemlistTableButton from '@/view/components/form/MemlistTableButton.vue';
import MemberEditorCompactModal from '@/view/components/member_editor_v2/MemberEditorCompactModal.vue';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';


import Confirm from '@/view/components/Confirm.vue';

import vuelidate from 'vuelidate';

Vue.use(vuelidate);

Vue.component('memlist-select-input', MemlistSelectInput);
Vue.component('memlist-select-input2', MemlistSelectInput2);
Vue.component('memlist-text-input', MemlistTextInput);
Vue.component('memlist-date-picker', MemlistDatePicker);
Vue.component('memlist-time-picker', MemlistTimePicker);
Vue.component('memlist-datetime-picker', MemlistDateTimePicker);
Vue.component('memlist-dropdown', MemlistDropdown);
Vue.component('memlist-checkbox', MemlistCheckbox);
Vue.component('memlist-contrast-button', MemlistContrastButton);
Vue.component('memlist-circular-button', MemlistCircularButton);
Vue.component('memlist-circular-select-button', MemlistCircularSelectButton);
Vue.component('memlist-circular-input-select-button', MemlistCircularInputSelectButton);
Vue.component('memlist-segmented-select-button', MemlistSegmentedSelectButton);
Vue.component('memlist-modal', MemlistModal);
Vue.component('memlist-code-box', MemlistCodeBox);
Vue.component('memlist-textarea', MemlistTextarea);
Vue.component('memlist-table-button', MemlistTableButton);
Vue.component('memlist-file-upload', MemlistFileUpload);
Vue.component('memlist-slider-input', MemlistSliderInput);
Vue.component('MemberEditorCompactModal', MemberEditorCompactModal);
Vue.component('memlist-inline-alert', MemlistInlineAlert);
Vue.component('memlist-spinner', MemlistSpinner);
Vue.component('memlist-card', MemlistCard);
Vue.component('memlist-button', MemlistButton);
Vue.component('m-row', MRow);
Vue.component('m-col', MCol);
Vue.component('memlist-badge', MemlistBadge);
Vue.component('memlist-icon-button', MemlistIconButton);
Vue.component('RightModalSaveAndCloseButtons', RightModalSaveAndCloseButtons);


Vue.component('Confirm', Confirm);

import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'

Vue.use(TiptapVuetifyPlugin, { vuetify, iconsGroup: 'md' });

// **DEFERRED INITIALIZATION**
loadCustomOverrides().then(() => {
  new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
  }).$mount('#app');
});
