<template>

  <div ref="page-container" class="page-container">
    <div ref="top-container" class="top-container">

      <ErrorPopup
        :error="error_popup_message"
      />


      <div class="left-menu" ref="navigation-drawer" >
        <v-navigation-drawer
          v-model="drawer"
          :mini-variant.sync="mini"
          permanent
          class="drawer"
        >
          <v-list-item
            class="px-2 drawer-header"
            >
            <v-list-item-avatar v-if="!mini">
              <v-icon>mdi-account</v-icon>
            </v-list-item-avatar>
            <v-btn
              v-else-if="!is_mobile"
              icon
              @click.stop="toggle_mini()"
            >
              <v-icon v-if="!mini">mdi-chevron-left</v-icon>
              <v-icon v-else="!mini">mdi-chevron-right</v-icon>
            </v-btn>

            <v-list-item-title>{{ $t('MEMBER_EDITOR.EDIT_MEMBER') }}</v-list-item-title>

            <v-btn
              v-if="!is_mobile"
              icon
              @click.stop="toggle_mini()"
            >
              <v-icon v-if="!mini">mdi-chevron-left</v-icon>
              <v-icon v-else="!mini">mdi-chevron-right</v-icon>
            </v-btn>
          </v-list-item>

          <v-divider></v-divider>

          <v-list dense>
            <v-list-item-group
              v-model="selected_item"
              active-class="selected-item-active"
              color="indigo"
              @change="list_item_group_change"
            >
              <v-list-item
                v-for="item in unlocked_sections"
                :key="item.title"
                link
                v-if="item.hide_on_create ? !creating : true"
              >
                <v-list-item-icon>
                  <v-icon style="min-width:36px;">{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="fixed-font">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </div>

      <!-- fill the rest of the width -->
      <div class="content flex-grow-1 flex-fill">
        <div v-if="member && member.reg" style="padding: 8px; background-color: #ffb85d;">{{ $t('MEMBER.PENDING_PAYMENT_REG') }}</div>
        <v-card class="content-card d-flex flex-column flex-fill" :style="(member.deceased || member.deactivated) ? { 'background-color': '#ff9797', 'margin-right': '-16px'} : { 'margin-right': '-16px' }">
          <v-card-title class="card-title">
            <b-row>
              <b-col lg="12" sm="12">

                <p class="card-title-p">{{ creating ? ($t('MEMBER_EDITOR.CREATE_MEMBER') ) : ($t('MEMBER_EDITOR.EDIT_MEMBER') + ': ' + member.public_id + ' ' + secret_name ) }} </p>
              </b-col>

            </b-row>

          </v-card-title>

          <MemberEditorDetails
            v-if="selected_section_name === 'personal_details'"
            :member="member"
            :requirements="requirements"

            :unlocks="get_unlock_section_fields_by_name('personal_details')"
            ref="member-editor-details"

            @change="set_member"
            @update="update_member"
            @create="create_member"
            @save_from_spar="save_from_spar"
          />

          <MemberEditorContactInformation
            v-if="selected_section_name === 'contact_information'"
            :unlocks="get_unlock_section_fields_by_name('contact_information')"
            :member="member"

            @change="set_member"
            @update="update_member"

          />

          <MemberEditorStatus
            v-if="selected_section_name === 'status'"
            :member="member"

            @change="set_member"
            @update="update_member"

          />

          <MemberCompaniesView
            v-if="selected_section_name === 'memberships'"
            :member="member"
            :creating_member="creating"
            :items="member_companies"

            @created="member_company_created"
            @updated="member_company_updated"
            @deleted="member_company_deleted"
          />

          <MemberEvents
            v-if="selected_section_name === 'events'"
            :member="member"
            :creating_member="creating"
            :items="member_events"
          />

          <MemberEducationsView
            v-if="selected_section_name === 'educations'"
            :member="member"
            :creating_member="creating"
            :items="member_educations"
          />

          <!-- old legacy family due to lack of time -->
          <MemberFamilyView
            v-if="selected_section_name === 'family'"
            :member="member"
            :creating_member="creating"
            @on_family_data_changed="on_family_data_changed"
          />

          <!-- old legacy parent due to lack of time -->
          <MemberParentsView
            v-if="selected_section_name === 'parents'"
            :member="member"
            :creating_member="creating"
            :items="member_parents"
            @updated="parent_updated"
            @created="parent_created"
            @deleted="parent_deleted"
          />

          <MemberFaithView
            v-if="selected_section_name === 'faith'"
            :member="member"
            :member_companies="member_companies"
            :creating_member="creating"
          />

          <!-- old legacy -->
          <MemberPropertyAssignView
            v-if="selected_section_name === 'attributes'"
            :member="member"
            :creating_member="creating"
            :items="member_property_assign"
            @deleted="delete_member_property_assign"
            @created="created_member_property_assign"
            @updated="updated_member_property_assign"
          />

          <!-- old legacy -->
          <MemberFilesView
            v-if="selected_section_name === 'files'"
            :member="member"
            :items="member_files"
            :creating_member="creating"
            @deleted="file_deleted"
            @created="file_created"
          />

          <!-- old legacy -->
          <MemberRenewalsView
            v-if="selected_section_name === 'renewals'"
            :member="member"
            :creating_member="creating"
          />

          <!-- old legacy -->
          <MemberInvoicesView
            v-if="selected_section_name === 'invoices'"
            :items="member_invoices"
            :member="member"
            :creating_member="creating"
            @created="created_invoice"
            @updated="updated_invoice"
            @deleted="deleted_invoice"
            @regenerated="regenerated_invoice"
          />

          <!-- old legacy -->
          <MemberShopOrdersView
            v-if="selected_section_name === 'payments'"
            :items="member_shop_orders"
            :member="member"
            :creating_member="creating"

            @delete="delete_shop_order"
          />

          <!-- old legacy -->
          <MemberNotesView
            v-if="selected_section_name === 'notes'"
            :member="member"
            :creating_member="creating"
            @update="update_member_notes"
          />

          <!-- old legacy -->
          <MemberOptionsView
            v-if="selected_section_name === 'options'"
            :member="member"
            :creating_member="creating"
            @onDelete="delete_member"
          />

          <MemberOtherView
            v-if="selected_section_name === 'other'"
            :member="member"
            :creating_member="creating"
          />

        </v-card>

      </div>

    </div>

  </div>

</template>


<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { SET_MEMBER_ID } from '../../../core/services/store/common.module';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';

import { is_mobile } from '@/core/services/utils';
import MemberCompaniesView from '@/view/components/member_editor_v2/sections/MemberCompaniesView.vue';
import MemberEvents from '@/view/components/member_editor_v2/sections/MemberEvents.vue';
import MemberEducationsView from '@/view/components/member_editor_v2/sections/MemberEducationsView.vue';
import MemberFamilyView from '@/view/components/member_editor_v2/sections/MemberFamilyView.vue';
import MemberParentsView from '@/view/components/member_editor_v2/sections/MemberParentsView.vue';
import MemberFaithView from '@/view/components/member_editor_v2/sections/MemberFaithView.vue';

import MemberFilesView from '@/view/components/member_editor_v2/sections/MemberFilesView.vue';

import MemberRenewalsView from '@/view/components/member_editor_v2/sections/MemberRenewalsView.vue';
import MemberInvoicesView from '@/view/components/member_editor_v2/sections/MemberInvoicesView.vue';
import MemberShopOrdersView from '@/view/components/member_editor_v2/sections/MemberShopOrdersView.vue';
import MemberNotesView from '@/view/components/member_editor_v2/sections/MemberNotesView.vue';
import MemberOptionsView from '@/view/components/member_editor_v2/sections/MemberOptionsView.vue';
import MemberOtherView from '@/view/components/member_editor_v2/sections/MemberOtherView.vue';

import MemberEditorDetails from '@/view/components/member_editor_v2/sections/MemberEditorDetails.vue';
import MemberEditorStatus from '@/view/components/member_editor_v2/sections/MemberEditorStatus.vue';
import MemberEditorContactInformation from '@/view/components/member_editor_v2/sections/MemberEditorContactInformation.vue';
import NarrowSearchBar from '@/view/components/member_editor_v2/search/NarrowSearchBar.vue';


import ErrorPopup from '@/view/components/ErrorPopup.vue';
import MemberPropertyAssignView from '@/view/components/member_editor_v2/sections/MemberPropertyAssignView.vue';

export default {
  name: 'member-editor-compact',
  components: {
    MemberEditorDetails,
    MemberEditorContactInformation,
    MemberEditorStatus,
    NarrowSearchBar,
    MemberEvents,
    MemberEducationsView,
    MemberCompaniesView,
    MemberFamilyView,
    MemberParentsView,
    MemberFaithView,
    
    MemberFilesView,

    MemberRenewalsView,
    MemberInvoicesView,
    MemberShopOrdersView,
    MemberNotesView,
    MemberOptionsView,
    MemberOtherView,
    MemberPropertyAssignView,
    
    ErrorPopup
  },
  props: ['member_id'],
  emits: ['created', 'updated', 'deleted'],
  computed: {
    ...mapGetters(['selected_shop_item_id', 'currentCompanyId', 'currentPeriodId', 'currentUserId', 'companies', 'periods', 'profile', 'settings','isTHS', 'sid']),
    creating() {
      return this.member === null || this.member.member_id === undefined;
    },
    secret_name() {
      return this.member.secret ? '*********' : this.member.firstname + ' ' + this.member.lastname;
    },
    is_mobile() {
      return is_mobile();
    },
    selected_section_name() {
      if (this.selected_item === undefined) {
        return '';
      }

      if (this.unlocked_sections[this.selected_item] === undefined) {
        return '';
      }

      return this.unlocked_sections[this.selected_item].name;
    }
  },
  mixins: [ toasts ],
  watch: {
    mini(newVal) {
      if (is_mobile()) {
        this.mini = true;
      }
    },
    selected_item(newVal, oldVal) {
      if (newVal === undefined) {
        this.selected_item = oldVal;
      }

      if (is_mobile()) {
        this.mini = true;
      }
    },
    member_id: function (val) {
      if (val) {
        this.select_member_id(val);
      }
    }
  },
  mounted() {
    if (this.member_id) {
      this.local_member_id = this.member_id;
    }
    if (is_mobile()) {
      this.mini = true;
    }

    this.$nextTick(()=>{
      if (this.local_member_id) {
        this.select_member_id(this.local_member_id);
      }
    });

    this.load_settings();
  },
  methods: {

    created_member_property_assign(assign_item) {
      this.toastr('success', this.$t('COMMON.OK'), this.$t('ATTRIBUTE.CREATED'));

      this.load_member_properties(this.member.member_id);
    },

    updated_member_property_assign(assign_item) {
      this.toastr('success', this.$t('COMMON.OK'), this.$t('ATTRIBUTE.UPDATED'));

      this.load_member_properties(this.member.member_id);
    },

    delete_member_property_assign(id) {
      this.load_member_properties(this.member.member_id);
    },

    file_deleted(file_id) {
      this.member_files = this.member_files.filter(item => item.file_id !== file_id);

      this.toastr('success', this.$t('COMMON.OK'), this.$t('FILE.DELETED'));
    },

    file_created(file) {
      this.member_files.push(file);
    },

    async delete_shop_order(item) {
      try {
        const res = await axios.delete(`/shop_order/${item.shop_order_id}`);

        if (res.status === 204) {
          this.member_shop_orders = this.member_shop_orders.filter(i => i.shop_order_id !== item.shop_order_id);

          this.toastr('success', this.$t('COMMON.OK'), this.$t('SHOP_ORDER.SHOPORDER_DELETED'));

          return;
        }
      }
      catch (err) {
        console.error('delete_shop_order error', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SHOP_ORDER.UNABLE_DELETE'));
    },

    parent_updated(parent) {

      if (parent.member_id) {
        const index = this.member_parents.findIndex(item => parent.member_id === item.member_id);

        this.member_parents[index] = parent;
      }
      else {
        const index = this.member_parents.findIndex(item => parent.person_id === item.person_id);

        this.member_parents[index] = parent;
      }

      this.member_parents = [ ...this.member_parents ];
    },

    parent_created(parent) {
      this.member_parents.unshift(parent);
    },

    parent_deleted(parent) {

      if (parent.member_id) {
        this.member_parents = this.member_parents.filter(item => parent.member_id !== item.member_id);
      }
      else {
        this.member_parents = this.member_parents.filter(item => parent.person_id !== item.person_id);
      }

    },

    get_unlock_section_fields_by_name(name) {
      const obj = this.shown_settings_data.find(item => item.name === name);

      if (obj === undefined) {
        return [];
      }

      return obj.children;
    },


    has_unlocked_section(name) {
      const unlock = this.shown_settings_data.find(item => item.name === name);

      return unlock !== undefined && unlock.value;
    },

    member_company_created(item) {
      this.member_companies.push(item);
      this.member_companies = [...this.member_companies];

      this.load_member_files(this.member.member_id);
    },

    member_company_updated(item) {
      const found_index = this.member_companies.findIndex(i => i.member_company_id === item.member_company_id);

      if (found_index >= 0) {
        this.member_companies[found_index] = item;
        this.member_companies = [ ...this.member_companies ];
      }

      this.load_member_files(this.member.member_id);
    },

    member_company_deleted(member_company_id) {
      this.member_companies = this.member_companies.filter(item => item.member_company_id !== member_company_id);

      this.load_member_files(this.member.member_id);
    },

    async select_member_id(member_id) {
      this.local_member_id = member_id;

      if (member_id === null) {
        this.member = {};

        return;
      }

      const member = await this.load_member(member_id);

      if (member === null) {
        console.error('unable to select member ', member_id);
      }
      await this.member_selected(member);
    },

    member_selected(member) {
      this.member = member;
      this.show = true;

      this.load_member_companies(this.member.member_id);
      this.load_member_events(this.member.member_id);
      this.load_member_educations(this.member.member_id);
      this.load_member_files(this.member.member_id);
      this.load_member_invoices(this.member.member_id);
      this.load_member_shop_orders(this.member.member_id);
      this.load_member_parents(this.member.member_id);
      this.load_member_properties(this.member.member_id);
    },

    save_data() {
      /// save data is only called when member exists already


      this.$nextTick(() => {

        if (this.creating) {
          //this.create_member(this.member);
        }
        else {
          this.update_member(this.member);
        }
      });

    },

    create_submit() {

      this.create_member(this.member);
    },

    async load_settings() {
      try {
        const result = await axios.get(`/company/${this.sid}`);

        if (result.status === 200) {
          this.shown_settings_data = result.data.company_settings.member_editor.editor_unlocks;
          this.requirements = result.data.requirements;

          for (const unlock of this.shown_settings_data) {
            if (!unlock.value) {
              continue;
            }
            this.unlocked_sections.push(this.all_sections.find(item => item.name === unlock.name));
          }

        }
      }
      catch (err) {
        console.error('load_settings error', err);
      }
    },

    async load_member(member_id) {
      try {
      
        const response = await axios.get(`/member/${member_id}`);

        if (response.status === 200) {
          return response.data.member;
        }

        console.error('unable to get member', response.status);

      }
      catch (err) {
        console.error('load_member', err);
      }

      return null;
    },

    async load_member_properties(member_id) {
      try {
        const response = await axios.get(`/member_property_assign/member/${member_id}`);

        if (response.status === 200) {
          this.member_property_assign = response.data;
          return;
        }

        console.error('unable to get load_member_properties', response.status);

      }
      catch (err) {
        console.error('load_member_properties', err);
      }
    },

    async load_member_companies(member_id) {
      try {
        const response = await axios.get(`/member_company/member/${member_id}`);

        if (response.status === 200) {
          this.member_companies = response.data;
          return;
        }

        console.error('unable to get member_companies', response.status);

      }
      catch (err) {
        console.error('load_member_companies', err);
      }
    },

    async load_member_parents(member_id) {
      try {
        const response = await axios.get(`/member/parents/${member_id}`);

        if (response.status === 200) {
          this.member_parents = response.data;
          return;
        }

        console.error('unable to get member_events', response.status);

      }
      catch (err) {
        console.error('load_member_parents', err);
      }
    },

    async load_member_events(member_id) {
      try {
        const response = await axios.get(`/member_event/member/${member_id}`);

        if (response.status === 200) {
          this.member_events = response.data;
          return;
        }

        console.error('unable to get member_events', response.status);

      }
      catch (err) {
        console.error('load_member_events', err);
      }
    },

    async load_member_educations(member_id) {
      try {
        const response = await axios.get(`/education/member/${member_id}`);

        if (response.status === 200) {
          this.member_educations = response.data;
          return;
        }

        console.error('unable to get member_educations', response.status);

      }
      catch (err) {
        console.error('load_member_educations', err);
      }
    },

    async load_member_files(member_id) {
      try {
        const response = await axios.get(`/file/member/${member_id}`);

        if (response.status === 200) {
          this.member_files = response.data;
          return;
        }

        console.error('unable to get member files', response.status);

      }
      catch (err) {
        console.error('load_member_files', err);
      }
    },

    created_invoice(invoice) {

      this.member_invoices.unshift(invoice);
      this.member_invoices = [ ...this.member_invoices ];

      // we must reload the memberships because they have now been connected to an invoice
      this.load_member_companies(this.member.member_id);
    },

    deleted_invoice(invoice) {
      const item = this.member_invoices.find(item => item.invoice_id === invoice.invoice_id);
      item.status = invoice.status;
      this.member_invoices = [ ...this.member_invoices ];
    },

    updated_invoice(invoice_id) {
      this.load_member_invoices(this.member.member_id);
      this.load_member_companies(this.member.member_id);
    },

    regenerated_invoice() {
      this.load_member_invoices(this.member.member_id);
    },

    async save_from_spar(member) {
      if (member.member_id) {
        this.update_member(member);
      }
      else {
        this.create_member(member);
      }
    },

    async create_member(member) {
      try {

        const res = await axios.post(`/member`, {
          ...member,
          company_id: this.currentCompanyId,
          period_id: this.currentPeriodId,
          shop_item_id: this.selected_shop_item_id }
        );

        if (res.status === 403) {
          this.error_popup_message = 'ERR_FORBIDDEN';

          this.$nextTick(()=>{ this.error_popup_message = null; });
          return;
        }

        if (res.status !== 201) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_CREATE_MEMBER'));
          console.error('unable to create member', res.status);
          return;
        }

        this.select_member_id(res.data.member_id);

        this.$emit('created', res.data);
      }
      catch (err) {
        console.error(`create_member error: ${err}`)
      }

    },


    set_member(member) {
      this.member = { ...member };
    },

    async update_member(member) {
      try {

        const res = await axios.put(`/member/${member.member_id}`, member);

        if (res.status === 403) {
          this.error_popup_message = 'ERR_FORBIDDEN';

          this.$nextTick(()=>{ this.error_popup_message = null; });
          return;
        }

        if (res.status === 200 || res.status === 204) {

          this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.MEMBER_UPDATED'));

          this.$emit('updated', member);

          this.member = { ...member };

          return;
        }

        console.error('unable to update_member', res.status);
      }
      catch (err) {
        console.error(`update_member error: ${err}`)
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_UPDATE_MEMBER'));
    },


    async update_member_notes(member_id, notes, mm) {
      try {

        const res = await axios.put(`/member/${member_id}`, { notes: notes });

        if (res.status !== 200) {
          console.error('unable to update member notes', res.status);
          return;
        }

        this.member.notes = notes;

        this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.MEMBER_UPDATED'));
      }
      catch (err) {
        console.error(`update_member_notes error: ${err}`)
      }
    },

    async load_member_invoices(member_id) {
      try {
        const response = await axios.get(`/invoice/member/${member_id}`);

        if (response.status === 200) {
          this.member_invoices = response.data;
          return;
        }

        console.error('unable to get member invoices', response.status);

      }
      catch (err) {
        console.error('load_member_invoices', err);
      }
    },

    async load_member_shop_orders(member_id) {
      try {
        const response = await axios.get(`/shop_order/member/${member_id}`);

        if (response.status === 200) {
          this.member_shop_orders = response.data;
          return;
        }

        console.error('unable to get member shop orders', response.status);

      }
      catch (err) {
        console.error('load_member_shop_orders', err);
      }
    },

    async delete_member(member_id) {

      try {

        const res = await axios.delete(`/member/${member_id}`);

        if (res.status === 403) {
          this.error_popup_message = 'ERR_FORBIDDEN';

          this.$nextTick(()=>{ this.error_popup_message = null; });
          return;
        }

        if (res.status === 204) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.MEMBER_DELETED'));
          this.clear();
          this.$emit('deleted', member_id);
          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_DELETE_MEMBER'));
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_DELETE_MEMBER'));
        console.error(err);
      }
    },

    on_family_data_changed() {
      this.load_member(this.member.member_id);
    },

    toggle_mini() {
      if (is_mobile()) {
        this.mini = true;
        return;
      }

      this.mini = !this.mini;
    },

    list_item_group_change() {
      if (is_mobile()) {
        this.mini = true;
      }
    },

    clear() {
      this.member = {};
      this.local_member_id = null;
      this.member_companies = [];
      this.member_events = [];
      this.member_educations = [];
      this.member_files = [];
      this.member_invoices = [];
      this.member_shop_orders = [];
    }

  },
  data() {
    return {
      error_popup_message: null,
      local_member_id: null,
      requirements: [],

      show: false,

      member: {

      },

      // which fields to show
      shown_settings_data: [],

      member_events: [],
      member_educations: [],
      member_companies: [],
      member_files: [],
      member_shop_orders: [],
      member_invoices: [],
      member_parents: [],
      member_property_assign: [],

      content_style: null,
      bottom_search_style: null,

      drawer: false,
      selected_item: 0,

      all_sections: [
        { title: this.$t('MEMBER_EDITOR.PERSONAL_DETAILS'), icon: 'mdi-account-details', name: 'personal_details', hide_on_create: false },
        { title: this.$t('MEMBER_EDITOR.CONTACT_INFORMATION'), icon: 'mdi-file-document', name: 'contact_information', hide_on_create: true },
        { title: this.$t('MEMBER_EDITOR.STATUS'), icon: 'mdi-account-clock', name: 'status', hide_on_create: true },
        { title: this.$t('MEMBER_EDITOR.MEMBERSHIPS'), icon: 'mdi-certificate', name: 'memberships', hide_on_create: true },
        { title: this.$t('MEMBER_EDITOR.EVENTS'), icon: 'mdi-calendar-month', name: 'events', hide_on_create: true },
        { title: this.$t('MEMBER_EDITOR.EDUCATIONS'), icon: 'mdi-school', name: 'educations', hide_on_create: true },
        { title: this.$t('MEMBER_EDITOR.FAMILY'), icon: 'mdi-home-heart', name: 'family', hide_on_create: true },
        { title: this.$t('MEMBER_EDITOR.PARENTS'), icon: 'mdi-crown', name: 'parents', hide_on_create: true },
        { title: this.$t('MEMBER_EDITOR.FAITH'), icon: 'mdi-book-open-page-variant', name: 'faith', hide_on_create: true },
        { title: this.$t('MEMBER_EDITOR.ATTRIBUTES'), icon: 'mdi-format-list-bulleted', name: 'attributes', hide_on_create: true },
        { title: this.$t('MEMBER_EDITOR.FILES'), icon: 'mdi-file-cabinet', name: 'files', hide_on_create: true },
        { title: this.$t('MEMBER_EDITOR.RENEWALS'), icon: 'mdi-folder-star-outline', name: 'renewals', hide_on_create: true },
        { title: this.$t('MEMBER_EDITOR.INVOICES'), icon: 'mdi-file-send', name: 'invoices', hide_on_create: true },
        { title: this.$t('MEMBER_EDITOR.PAYMENTS'), icon: 'mdi-credit-card', name: 'payments', hide_on_create: true },
        { title: this.$t('MEMBER_EDITOR.NOTES'), icon: 'mdi-pencil-box-outline', name: 'notes', hide_on_create: true },
        { title: this.$t('MEMBER_EDITOR.OPTIONS'), icon: 'mdi-cursor-pointer', name: 'options', hide_on_create: true },
        { title: this.$t('MEMBER_EDITOR.OTHER'), icon: 'mdi-archive', name: 'other', hide_on_create: true },
      ],

      // the final list of sections to show
      unlocked_sections: [

      ],
      mini: false,
    }
  },
}

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";

.row {
  margin-top: -12px !important;
}

.page-container {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.fill-rest {
  flex: 1 1 auto;
}


.quick-search-card {
  overflow: scroll;
}

.selected-item-active {
  border-radius: 4px;
  background: #f4fcff;
  color: black;
}

.card-title {
  background-color:#f7f7f7;
  border-bottom:1px solid #dedede;
  padding-bottom: 15px;

}

.card-title-p {
  margin-bottom: 8px !important;
  margin-top: 7px !important;
}


.top-container {
  display: flex;
}

.content {
  flex-grow: 1;
  margin-top: 0px !important;
  padding-top: 0px !important;
  margin-left: 8px !important;
}



.drawer-header {
  margin-bottom: -13px !important;
}

.drawer {
  border-right: 1px solid #dddddd !important;
}

.fixed-font {
  font-size: 0.95rem !important;
  font-weight: 500;
  line-height: 1rem;
}

:deep .v-application--wrap {
  min-height: 0px !important;
}

</style>
