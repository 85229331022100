
import axios from 'axios';
import store from './store';

export async function get_file(file_id) {
  try {

    const res = await axios.get(`/file/${file_id}`);

    if (res.status !== 200) {
      return null;
    }

    return { ...res.data, public_path: get_base_url() + `/dlfile/${res.data.linkstr}` };
  }
  catch (err) {
    console.error('get_file error', err);

    return null;
  }
}

export async function download_file_direct(file_id) {
  try {

    const res = await axios.get(`/file/${file_id}`);

    if (res.status !== 201) {
      return false;
    }

    downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);

    return true;
  }
  catch (err) {
    console.error('download_file error', err);

    return false;
  }
}

export function get_base_url() {
  if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_API_DOMAIN) { 
    return 'http://' + process.env.VUE_APP_API_DOMAIN;
  }
  
  const base_url = window.location.protocol + '//' + window.location.hostname;

  return base_url;
}

export function get_domain_url() {
  if (process.env.NODE_ENV === 'development') { 
    return window.location.protocol + '//' + window.location.hostname + ':' + window.location.port;
  }
  
  return window.location.protocol + '//' + window.location.hostname;
}


export function forceFileDownload(response, title) {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', title);
  document.body.appendChild(link);
  link.click();
}

export function downloadWithAxios(url, title) {
  axios({
    method: 'get',
    url,
    responseType: 'arraybuffer',
    headers: {
      common: {
        'X-Member-Token': store.getters.mtoken,
        'X-Access-Token': store.getters.token
      }
    }
  })
  .then(response => {
    forceFileDownload(response, title);
  })
  .catch(() => console.error('error occured'));
}