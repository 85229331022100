<template>
  <div :class="['memlist-file-upload-container', style_class, 'ml-file-upload']">
    <div v-if="layout === 'horizontal'">
      <div v-if="title" class="horizontal1">
        <label>{{ title }}</label>
      </div>
      <div class="horizontal2">
        <input hidden type="file" ref="file_input" @change="handle_file_upload" />
        <div class="file-upload-wrapper">
          <input
            type="text"
            :value="file_name"
            :placeholder="placeholder"
            :disabled="true"
            class="file-upload-input"
          />
          <button class="file-upload-button" @click.prevent="select_file">
            {{ $t('FILE.UPLOAD') }}
          </button>
          <a v-if="enable_delete" class="btn btn-icon btn-light btn-sm" @click="deleteFileClicked">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
            </span>
          </a>
        </div>
        <div class="error-text" v-if="required && invalid_text && invalid_text.length > 0 && !file_selected">
          {{ invalid_text }}
        </div>
        <div v-if="type === 'image' && preview_url" class="image-preview">
          <img :src="preview_url" alt="Uploaded Image" class="uploaded-image" />
        </div>
      </div>
    </div>

    <div v-if="layout === 'vertical'">
      <div v-if="title" class="vertical1">
        <label>{{ title }}</label>
      </div>
      <div class="vertical2">
        <input hidden type="file" ref="file_input" @change="handle_file_upload" />
        <div class="file-upload-wrapper">
          <input
            type="text"
            :value="file_name"
            :placeholder="placeholder"
            :disabled="true"
            class="file-upload-input"
          />
          <button class="file-upload-button" @click.prevent="select_file">
            {{ $t('FILE.UPLOAD') }}
          </button>
          <a v-if="enable_delete" class="btn btn-icon btn-light btn-sm" @click="deleteFileClicked">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
            </span>
          </a>
        </div>
        <div class="error-text" v-if="required && invalid_text && invalid_text.length > 0 && !file_selected">
          {{ invalid_text }}
        </div>
        <div v-if="type === 'image' && preview_url" class="image-preview">
          <img :src="preview_url" alt="Uploaded Image" class="uploaded-image" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { upload_file } from '@/core/services/fileUpload';
import { get_base_url } from '@/core/services/fileDownload';
import axios from 'axios';

export default {
  name: 'MemlistFileUpload',
  mixins: [toasts],
  props: {
    layout: {
      type: String,
      default: 'horizontal'
    },
    title: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    invalid_text: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Choose a file...'
    },
    file_type: {
      type: String,
      default: 'FILE_CSV'
    },
    is_public: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      file_name: '',
      file_selected: false,
      preview_url: null,
      enable_delete: false
    };
  },
  computed: {
    style_class() {
      return this.layout === 'vertical' ? 'vertical-container' : 'horizontal-container';
    },
    
  },
  watch: {
    value(new_value) {
      if (this.type === 'image' && new_value) {
        this.load_image_url(new_value);

        this.enable_delete = true;
      }
    }
  },
  mounted() {
    if (this.type === 'image' && this.value) {
      this.load_image_url(this.value);

      this.enable_delete = true;
    }
  },
  methods: {
    select_file() {
      this.$refs.file_input.click();
    },

    async handle_file_upload() {
      const file = this.$refs.file_input.files[0];

      if (!file) return;

      if (file.size > 50000000) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('FILE.MUST_BE_LESS'));
        return;
      }

      this.file_name = file.name;
      this.file_selected = true;

      try {
        const uploaded_file = await upload_file(file, { file_type: this.file_type, is_public: this.is_public });
        this.$emit('uploaded', uploaded_file);
        this.toastr('success', this.$t('COMMON.OK'), this.$t('FILE.UPLOADED'));

        // Fetch and update preview URL if it's an image
        if (this.type === 'image') {
          this.load_image_url(uploaded_file.file_id);
        }
      } catch (err) {
        console.error('handle_file_upload error', err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('FILE.UNABLE_UPLOAD'));
      }
    },

    async load_image_url(file_id) {
      try {
        const res = await axios.get(`/file/${file_id}`);
        if (res.status === 200) {
          this.preview_url = get_base_url() + `/dlfile/${res.data.linkstr}`;
        }
      } catch (err) {
        console.error('load_image_url error', err);
        this.preview_url = null;
      }
    },

    deleteFileClicked() {
      this.file_name = '';
      this.file_selected = false;
      this.preview_url = null;
      this.$refs.file_input.value = ''; // Reset file input
      this.$emit('deleted'); // Emit delete event
      this.toastr('info', this.$t('COMMON.OK'), this.$t('FILE.DELETED'));
    }
  }
};
</script>

<style lang="scss" scoped>
.memlist-file-upload-container {
  display: flex;
  flex-direction: column;
}

.file-upload-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.file-upload-input {
  flex: 1;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  background-color: #ffffff;
  border: 1px solid #E4E6EF;
  border-radius: 0.42rem;
  box-shadow: none;
}

.file-upload-button {
  margin-left: 8px;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  background-color: #3699FF;
  color: white;
  border: none;
  border-radius: 0.42rem;
  cursor: pointer;
}

.file-upload-button:hover {
  background-color: #2d4fa0;
}

.image-preview {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.uploaded-image {
  max-width: 100%;
  max-height: 150px;
  border-radius: 5px;
  border: 1px solid #ddd;
}
</style>
