<template>
  <div :class="['memlist-text-input-container', styleClass, 'ml-text-input']">
    
    <div v-if="layout === 'horizontal'">
      <div v-if="title" class="horizontal1">
        <label>{{ title }}</label>
      </div>
      <div class="horizontal2">
        <input
          ref="text-input"
          @input="on_input"
          v-on:blur="on_blur"
          :type="type"
          :value="local_value"
          :placeholder="placeholder"
          :disabled="disabled"
          :style="computedStyle"
          :class="[state_class, inputStyleClass]"
        />
        <div class="error-text" v-if="required && invalid_text && invalid_text.length > 0 && local_state === false">
          {{ invalid_text }}
        </div>
      </div>
    </div>

    <div v-if="layout === 'vertical'">
      <div v-if="title" class="vertical1">
        <label>{{ title }}</label>
      </div>
      <div class="vertical2">
        <input
          ref="text-input"
          @input="on_input"
          v-on:blur="on_blur"
          :type="type"
          :value="local_value"
          :placeholder="placeholder"
          :disabled="disabled"
          :style="computedStyle"
          :class="[state_class, inputStyleClass ]"
        />
        <div class="error-text" v-if="required && invalid_text && invalid_text.length > 0 && local_state === false">
          {{ invalid_text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { validate_email } from '@/core/services/email';
import { validatePersonnr } from '@/core/services/personnr';

export default {
  name: 'MemlistTextInput',
  props: {
    size: {
      type: String,
      default: 'sm', // Default size
      validator: (value) => ['sm', 'md', 'lg'].includes(value),
    },
    variation: {
      type: String,
      default: 'normal'
    },
    layout: {
      type: String,
      default: 'horizontal'
    },
    title: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    validate: {
      type: Number,
      default: 0
    },
    invalid_text: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    validation_type: {
      type: String,
      default: 'TEXT'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: [String, Number], // For Vue 2
    
    placeholder: {
      type: String,
      default: ''
    },
    validation_function: {
      type: Function,
      default: null
    }
  },
  
  mixins: [toasts],
  emits: ['input', 'change', 'validated', 'blur'],

  components: {
    
  },

  watch: {
    validate(newValue) {
      if (newValue !== 0) {
        this.local_state = this.validate_input();
      }
    },

    value(newValue) {
      this.local_value = newValue;
    }
  },

  computed: {
    computedStyle() {
      const sizeStyles = {
        sm: {
          fontSize: '14px',
          padding: '6px 10px',
          height: '48px',
        },
        md: {
          fontSize: '16px',
          padding: '8px 12px',
          height: '56px',
        },
        lg: {
          fontSize: '18px',
          padding: '10px 14px',
          height: '60px',
        },
      };

      return sizeStyles[this.size] || sizeStyles.sm;
    },
    sizeClass() {
      return {
        sm: 'text-input-sm',
        md: 'text-input-md',
        lg: 'text-input-lg',
      }[this.size] || 'text-input-sm';
    }, 
    state_class() {
      if (!this.required) {
        return 'memlist-text-input';
      }
      if (this.local_state !== null) {
        return this.local_state ? 'memlist-text-input success1' : 'memlist-text-input failure1';
      }
      return 'memlist-text-input';
    },
    // New computed properties for dynamic styles
    inputStyleClass() {
      return this.variation === 'enhanced' ? 'enhanced-style' : '';
    },
    styleClass() {
      return this.variation === 'enhanced' ? 'enhanced-container' : '';
    }
  },
  mounted() {
    this.local_value = this.value;
  },
  methods: {
    on_blur() {
      this.$emit('blur', this.name, this.local_value);
    },
    focus() {
      this.$refs['text-input'][0].focus();
    },
    validate_input() {

      if (this.validation_function !== null) {
        const res = this.validation_function(this.local_value);
        
        this.$emit('validated', this.name, this.local_value, res);

        this.local_state = res;

        return res;
      }

      if (!this.required) {
        return true;
      }

      if (this.local_value === undefined) {
        this.local_value = '';
      }

      if (this.disabled) {
        this.$emit('validated', this.name, this.local_value, res);
        return true;
      }

      if (this.required && !this.local_value) {
        this.$emit('validated', this.name, this.local_value, false);
        return false;
      }

      if (this.validation_type === 'TEXT') {
        
        const res = this.local_value.length > 0;

        this.$emit('validated', this.name, this.local_value, res);

        return res;
      }
      else if (this.validation_type === 'PERSONNR') {
        const validated = validatePersonnr(this.local_value);

        if (validated.valid) {
          this.local_value = validated.formatted;

          this.$emit('validated', this.name, this.local_value, true);
          return true;
        }
        else {
          this.$emit('validated', this.name, this.local_value, false);
          return false;
        }
      }
      else if (this.validation_type === 'DOB') {
        const validated = validatePersonnr(this.local_value);

        if (validated.success) {
          this.local_value = validated.birthdate;

          this.$emit('validated', this.name, this.local_value, true);
          return true;
        }
        else {
          this.$emit('validated', this.name, this.local_value, false);
          return false;
        }
      }
      else if (this.validation_type === 'EMAIL') {
        const res = validate_email(this.local_value);

        this.$emit('validated', this.name, this.local_value, res);

        return res;
      }

      this.$emit('validated', this.name, this.local_value, true);
    },

    on_input(event) {
      const inputValue = event.target.value;

      this.local_value = inputValue;

      this.local_state = this.validate_input();   

      //this.$emit('update:value', this.name, inputValue); // For Vue 3
      this.$emit('input', inputValue); // For Vue 2
    }
  },
  data() {
    return {
      local_state: null,
      local_value: null
    };
  }
};
</script>

<style lang="css" scoped>

:deep(.text-input-sm) {
  font-size: 14px;
  padding: 6px 10px;
}

:deep(.text-input-md) {
  font-size: 16px;
  padding: 8px 12px;
}

:deep(.text-input-lg) {
  font-size: 18px;
  padding: 10px 14px;
}

input:focus {
  outline: none;
  box-shadow:0 1px 0 #3968ce;
  border-color:#3968ce;
}

.horizontal1 {
  float: left;
  width: 50%;
  text-align: right;
  padding-right: 16px;
  margin-top: 8px;
}

.horizontal2 {
  float: left;
  width: 50%;
}

label {
  font-weight: bold;
}

.vertical1 {
  margin-top: 8px;
}

.vertical2 {
  margin-right: 8px;
}

:deep .success1 {
  border: 1px solid #1BC5BD !important;
}

:deep .failure1 {
  border: 1px solid #fd397a !important;
}

:deep .mx-input {
  height: calc(1.5em + 1.3rem + 1px);
  cursor: pointer;
}

:deep input:disabled {
  background-color: #E4E6EF !important;
  opacity: 1;
}

.error-text {
  color: #fd397a;
  font-style: italic;
  margin-top: 6px;
}

:deep .memlist-text-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3F4254;
  background-color: #ffffff !important;
  background-clip: padding-box;
  border: 1px solid #E4E6EF;
  border-radius: 0.42rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Enhanced style for the input and container */
.enhanced-style {
  padding: 1rem 1.5rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.enhanced-container {
  padding: 1rem;
}
</style>
